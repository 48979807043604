import React, { Component } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import { Container, Row, Col } from 'react-grid-system'
import chroma from 'chroma-js'
import {
  MenuHorizontal,
  MenuItem,
  Button,
  Breakpoints,
  Scroll,
  ImageBasic,
  MediaQueries,
} from 'inno-components'
import { Material } from 'inno-components/build/Icons'
import {
  Link as ScrollLink,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from 'react-scroll'
import Config from '../../Config'
import Languages from './Languages'

import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'

import { isMobile } from "react-device-detect"

const offset = -48
const debounceTime = 10

const styles = StyleSheet.create({
  active: {
    color: '#fff',
    backgroundColor: chroma(Config.colors.primary)
      .alpha(0.8)
      .css(),
  },
  link: {
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'none',
    padding: '0.5em',
    margin: '2em 0',
    ':hover': {
      backgroundColor: chroma(Config.colors.primary)
        .alpha(0.8)
        .css(),
    },
    [MediaQueries.small]: {
      margin: '0.5em 0.5em',
    },
  },
  href: {
    ':hover': {
      color: '#fff',
    },
  },
  logo: {
    height: 'auto',
    width: '150px',
    [Breakpoints.small]: {
      width: '90px',
    },
  },
  icon: {
    color: Config.colors.primary,
  },
  menu: {
    alignItems: 'center',
  },
  menuContainer: {
    position: 'fixed',
    width: '100%',
    zIndex: 9,
    backgroundColor: '#000',
    padding: '0.3em 1.5em',
    [Breakpoints.small]: {
      padding: '0 1em',
    },
    transition: 'all 0.4s ease',
  },
  menuContainerBlock: {
    position: 'relative',
    display: 'block',
  },
  right: {
    display: 'flex',
  },
})

const LinkComponent = ({ link, active }) => {
  return (
    <Link
      page={pagesInfos.ComponentIndex}
      className={css(styles.link, active && styles.active)}
      query={'#' + link.name}
    >
      {link.label}
    </Link>
  )
}

const ScrollLinkComponent = ({ link, active, closeOverlay }) => {
  console.log('closeOverlay', closeOverlay)
  return (
    <ScrollLink
      to={link.name}
      className={css(styles.link)}
      spy
      activeClass={css(styles.active)}
      smooth
      offset={offset}
    >
      <span onClick={closeOverlay}>{link.label}</span>
    </ScrollLink>
  )
}
class Menu extends Component {
  render() {
    const { t } = this.props
    const links = [
      MenuItem({
        anchor: 'notre-entreprise',
        name: 'notre-entreprise',
        label: t('menu.entreprise'),
        offset: -60,
        LinkComponent: this.props.isDisplayFromHome
          ? ScrollLinkComponent
          : LinkComponent,
      }),
      MenuItem({
        anchor: 'vos-campagnes',
        name: 'vos-campagnes',
        label: t('menu.campagnes'),
        offset,
        LinkComponent: this.props.isDisplayFromHome
          ? ScrollLinkComponent
          : LinkComponent,
      }),
      MenuItem({
        anchor: 'contact',
        name: 'contact',
        label: t('menu.contact'),
        offset,
        LinkComponent: this.props.isDisplayFromHome
          ? ScrollLinkComponent
          : LinkComponent,
      }),
      MenuItem({
        anchor: 'references',
        name: 'references',
        label: t('menu.references'),
        offset,
        LinkComponent: this.props.isDisplayFromHome
          ? ScrollLinkComponent
          : LinkComponent,
      }),
      MenuItem({
        anchor: '',
        name: '',
        label: '',
        offset,
        LinkComponent: isMobile
          ? Languages
          : ScrollLinkComponent
          ,
      }),
    ]

    return (
      <div>
        <div
          className={css(
            styles.menuContainer,
            !this.props.isDisplayFromHome && styles.menuContainerBlock
          )}
        >
          <Container>
            <Row>
              <Col>
                <MenuHorizontal
                  LeftComponent={
                    <Link page={pagesInfos.ComponentIndex}>
                      <ImageBasic
                        src="https://storage.googleapis.com/innobelge-websites.appspot.com/sites/screening-media/images/logo-alt-blanc.svg"
                        className={css(styles.logo)}
                        fill={Config.colors.secondary}
                      />
                    </Link>
                  }
                  links={links}
                  className={css(styles.menu)}
                  RightComponent={
                    <div className={css(styles.right)}>
                      <a
                        className={css(styles.link) + ' phone-call'}
                        href="tel://+3227912113"
                      >
                        02.791.21.13
                      </a>
                      <Languages />
                    </div>
                  }
                  iconMobileColor={Config.colors.primary}
                  bgOverlayColor="rgba(0,0,0,0.95)"
                  iconCloseOverlayColor={Config.colors.primary}
                  activeClassName={css(styles.active)}
                  activeItemNames={[]}
                  IconOpenComponent={
                    <Material.MdMenu className={css(styles.icon)} />
                  }
                  IconCloseComponent={
                    <Material.MdClose className={css(styles.icon)} />
                  }
                  animation="hvr-shutter-out-horizontal"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    )
  }
}

Menu.propTypes = {
  isDisplayFromHome: PropTypes.bool,
}

Menu.defaultProps = {
  isDisplayFromHome: true,
}

export default withNamespaces('home')(Menu)
