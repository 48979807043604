const config = {
  url: 'https://www.aliaslanguage.be',
  name: 'screening-medias',
  head: {
    image: 'https://www.alias-language.be/images/Logo.jpg',
  },
  facebook: {
    prefix:
      'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb# business: http://ogp.me/ns/business#"',
    type: 'business.business',
  },
  colors: {
    primary: '#C81010',
    secondary: '#A40101',
    thirdary: '#29B6F6',
  },
  langs: {
    default: 'fr',
    locales: ['fr', 'en', 'nl'],
  },
  fonts: {
    defaultSize: '18px',
    primary: {
      name: "'Open Sans', sans-serif",
      url: 'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800',
    },
    secondary: {
      name: "'Playfair Display', sans-serif",
      url:
        'https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900',
    },
  },
}

export default config
