import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { reactI18nextModule } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

export const DEFAULT_LANG = 'fr'
export const LANGS = ['fr', 'nl']
export const NAMESPACES = ['home']

const options = {
  fallbackLng: DEFAULT_LANG,
  whitelist: LANGS,
  ns: NAMESPACES,
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  react: {
    wait: true,
  },
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  parseMissingKeyHandler: () => '', // Display an empty string when missing/loading key
}

const i18nContext = i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init(options)

export default i18nContext
