module.exports = {
  ComponentIndex: {
    id: 'ComponentIndex',
    namespace: 'home',
    path: {
      fr: '/',
      en: '/en/',
      nl: '/nl/',
    },
  },
  /************************************************ 
    Pie
  *************************************************/
  ComponentInstalltionEcrans: {
    id: 'ComponentInstalltionEcrans',
    namespace: 'home',
    path: {
      fr: '/installation-ecrans',
      en: '/en/installation-ecrans',
      nl: '/nl/scherminstallatie',
    },
  },
  ComponentCampagneDeNotoriete: {
    id: 'ComponentCampagneDeNotoriete',
    namespace: 'home',
    path: {
      fr: '/campagne-de-notoriete',
      en: '/en/campagne-de-notoriete',
      nl: '/nl/bekendheidscampagne',
    },
  },
  ComponentCampagneDeCommunication: {
    id: 'ComponentCampagneDeCommunication',
    namespace: 'home',
    path: {
      fr: '/campagne-de-communication',
      en: '/en/campagne-de-communication',
      nl: '/nl/communicatiecampagne',
    },
  },
}
