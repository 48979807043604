import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { GoogleMap } from 'inno-components'
import { StaticQuery, graphql } from 'gatsby'

const styles = StyleSheet.create({
  map: {},
})

const Map = () => (
  <StaticQuery
    query={graphql`
      query MapImageQuery {
        icon: file(relativePath: { eq: "favicon.png" }) {
          childImageSharp {
            fluid(maxWidth: 128, maxHeight: 128) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <GoogleMap
          center={{ lat: 50.8026347, lng: 4.4213836 }}
          containerElement={<div className={css(styles.map)} />}
          loadingElement={<div />}
          iconUrl={data.icon.childImageSharp.fluid.src}
          mapElement={<div style={{ height: '400px' }} />}
        />
      )
    }}
  />
)

export default Map
