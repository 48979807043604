import { MediaQueries } from 'inno-components'
import { StyleSheet, css } from 'aphrodite'
import Config from '../Config'

const styles = StyleSheet.create({
  section: {
    padding: '1.5em 1.5em',
    [MediaQueries.small]: {
      padding: '2em 4em',
    },
  },
  a: {
    textDecoration: 'none',
  },
  title: {
    fontSize: '1.4em',
    fontWeight: 800,
    marginBottom: '2em',
    color: Config.colors.primary,
    textAlign: 'center',
  },
  paragraph: {
    fontWeight: 300,
  },
  paragraphBordered: {
    display: 'block',
    paddingLeft: '1.5em',
    marginTop: '1.5em',
    borderLeft: `solid 6px ${Config.colors.primary}`,
  },
})

export default styles
