import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { MuiThemeProvider, CssBaseline, Favicon } from 'inno-components'

import SetLanguageFromPage from '../../config/i18n/SetLanguageFromPage'
import i18next from '../../config/i18n/context'

const getLanguage = () => i18next.language || window.localStorage.i18nextLng

const Layout = ({ children }) => {
  const lang = getLanguage()
  return (
    <>
      <CssBaseline />
      <Helmet
        title="Screening Media"
        meta={[
          { name: 'description', content: 'Sample' },
          { name: 'keywords', content: 'sample, something' },
        ]}
      >
        <html lang={lang} />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://storage.googleapis.com/innobelge-websites.appspot.com/commons/styles/hover.css"
        />
      </Helmet>
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
