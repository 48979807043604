import React, { Component } from 'react'
import { Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import cn from 'classnames'
import PropTypes from 'prop-types'
import i18next from '../config/i18n/context'

const styles = StyleSheet.create({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
})

const getLanguage = () => i18next.language || window.localStorage.i18nextLng

const DefaultLink = ({
  page,
  children,
  className,
  style,
  query,
  lang,
  replace,
}) => {
  let href = page.path[lang || getLanguage()]

  if (query) {
    href += query
  }

  return (
    <Link
      to={href}
      className={className || css(styles.link)}
      style={style}
      replace={replace}
    >
      {children}
    </Link>
  )
}

DefaultLink.propTypes = {
  children: PropTypes.element.isRequired,
  page: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  query: PropTypes.string,
  lang: PropTypes.string,
  replace: PropTypes.bool,
}

DefaultLink.defaultProps = {
  className: undefined,
  style: undefined,
  query: undefined,
  lang: undefined,
  replace: false,
}

export default withNamespaces('home')(DefaultLink)
