import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { Icon } from 'semantic-ui-react'
import { withNamespaces } from 'react-i18next'
import axios from 'axios'
import swal from 'sweetalert2'
import chroma from 'chroma-js'
import { Container, Row, Col } from 'react-grid-system'

import {
  Grid,
  Breakpoints,
  Paper,
  Section,
  FormBuilder,
  FormField,
  ContactTemplate,
  ContactTemplateSection,
} from 'inno-components'
import { Material } from 'inno-components/build/Icons'

import Classes from '../../styles/classes'

import Colors from '../../styles/colors'
import Config from '../../Config'

const styles = StyleSheet.create({
  paper: {
    padding: '2em',
    // background: 'url(/static/sites/alias-languages/images/illustrations/devis-gratuit.svg)',
    backgroundColor: Colors.white,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
    [Breakpoints.small]: {
      padding: '1.5em 0.2em',
    },
  },
  label: {
    display: 'block',
    marginBottom: '0.5em',
  },
  input: {
    display: 'block',
    padding: '0.5em',
    outline: 'none',
    border: 'solid 2px #333',
    width: '100%',
    borderRadius: '0.2em',
    backgroundColor: chroma(Colors.white)
      .brighten(0.2)
      .css(),
    '::-webkit-input-placeholder': {
      color: '#333',
    },
  },
  inputError: {
    color: '#e74c3c',
    borderColor: '#e74c3c',
    '::-webkit-input-placeholder': {
      color: '#e74c3c',
    },
  },
  error: {
    marginTop: '0.5em',
    fontWeight: 'bold',
    color: '#e74c3c',
  },
  dropzone: {
    border: 'solid 2px #333',
    width: '100%',
    borderRadius: '0.2em',
    padding: '0.5em',
    backgroundColor: '#eee',
    ':hover': {
      cursor: 'pointer',
    },
  },
  dropzoneSecondary: {
    backgroundColor: '#fff',
  },
  icon: {
    fontSize: '2.5em',
  },
  dropzoneContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    color: Colors.white,
    backgroundColor: Config.colors.secondary,
  },
  paragraph: {
    whiteSpace: 'pre-line',
  },
  section: {
    backgroundColor: '#F5F5F5',
  },
  sectionSecondary: {
    backgroundColor: '#ffff',
  },
})

export const sendMail = (from, to, subject, domain, html, attachements) => {
  const data = new FormData()
  data.append('from', from)
  data.append('to', to)
  data.append('subject', subject)
  data.append('domain', domain)
  data.append('html', html)

  if (attachements && attachements.length > 0) {
    attachements.forEach((attachement, i) => {
      data.append(`file${i}`, attachement)
    })
  }

  return axios.post(
    'https://us-central1-innobelge-websites.cloudfunctions.net/emails',
    data
  )
}

const InfoBox = ({ children }) => (
  <Grid.Column width={8}>
    <div
      style={{
        backgroundColor: '#fff',
        padding: '3em',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  </Grid.Column>
)

class Contact extends Component {
  state = {
    isLoading: false,
    isMessageSent: false,
  }
  componentDidMount() {}
  onSubmit = response => {
    const { page, isDisplayFromHome } = this.props
    const html = []
    response.values.forEach(value => {
      html.push(
        `<h3>${
          typeof value.label == 'string' ? value.label : 'Consentement GDPR'
        }</h3><p>${value.value}</p>`
      )
    })

    const section = ContactTemplateSection(
      'Nouvelle Demande De Devis',
      html.join('')
    )
    const template = ContactTemplate(
      'https://www.screeningmedia.com/static/favicon-5f5577c890e24a92bc38e3feeb0147b0-f8966.png',
      section
    )

    swal({
      title: "Message en cours d'envoi",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        //swal.showLoading()
      },
    })

    return sendMail(
      'noreply@screeningmedia.com',
      isDisplayFromHome
        ? 'info@screeningmedia.com; adrien.laroche@innosmile.be'
        : 'benjamin.marguerite@innosmile.be; adrien.laroche@innosmile.be',
      isDisplayFromHome ? "Demande d'offre" : page + " - Demande d'offre",
      'innobelge.be',
      template,
      response.files.fichiers
    )
      .then(() => {
        swal.hideLoading()
        //swal.close()
        swal(
          'Message Envoyé',
          'Nous reviendrons vers vous très prochainement',
          'success'
        )
      })
      .catch(error => {
        console.log(error)
        swal('Message Non Envoyé', error, 'error')
      })
  }
  render() {
    const { t, isDisplayFromHome } = this.props
    const fields = [
      [
        FormField({
          label: t('contact.societe'),
          name: 'societe',
          type: 'text',
          colClassName: "four wide column"
        }),
        FormField({
          label: t('contact.nom'),
          name: 'nom',
          type: 'text',
          colClassName: "six wide column",
          required: true,
        }),
        FormField({
          label: t('contact.email'),
          name: 'email',
          type: 'text',
          colClassName: "six wide column",
          required: true,
          validate: value => {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return {
              isValid: re.test(String(value).toLowerCase()),
              message: 'Email invalide',
            }
          },
        }),
      ],
      [
        FormField({
          label: t('contact.telephone'),
          name: 'telephone',
          type: 'text',
          colClassName: "six wide column",
        }),
        FormField({
          label: t('contact.langue'),
          name: 'langue',
          type: 'text',
          colClassName: "four wide column",
        }),
        FormField({
          label: t('contact.region'),
          name: 'region',
          type: 'text',
          colClassName: "six wide column",
        }),
      ],

      [
        FormField({
          label: 'Fichiers',
          name: 'fichiers',
          type: 'file',
          colClassName: "sixteen wide column",
        }),
      ],
      [
        FormField({
          label: t('contact.informations'),
          name: 'informations-complementaires',
          type: 'textarea',
          colClassName: "sixteen wide column",
        }),
      ],
    ]
    let Content = (
      <InfoBox>
        <p style={{ fontWeight: 'bold', marginBottom: '2em' }}>
          Message en cours d'envoi
        </p>
      </InfoBox>
    )
    if (!this.state.isLoading && this.state.isMessageSent) {
      Content = (
        <InfoBox>
          <p style={{ fontWeight: 'bold', marginBottom: '2em' }}>
            Message envoyé!
          </p>
          <Material.MdCloudDone style={{ color: '#4cd137', fontSize: '3em' }} />
        </InfoBox>
      )
    } else if (!this.state.isLoading && !this.state.isMessageSent) {
      Content = (
        <FormBuilder
          fields={fields}
          showLabels={false}
          inputClassName={css(styles.input)}
          inputErrorClassName={css(styles.inputError)}
          errorClassName={css(styles.error)}
          textAreaClassName={css(styles.input)}
          dropzoneClassName={css(
            styles.dropzone,
            !isDisplayFromHome && styles.dropzoneSecondary
          )}
          labelClassName={css(styles.label)}
          dropzoneContent={
            <div className={css(styles.dropzoneContent)}>
              <Material.MdFileUpload className={css(styles.icon)} />

              <p>{t('contact.dropzone')}</p>
            </div>
          }
          onSubmit={this.onSubmit}
          buttonSubmitClassName={css(styles.button) + ' send-form'}
        />
      )
    }
    return (
      <Section
        className={css(
          Classes.section,
          isDisplayFromHome ? styles.section : styles.sectionSecondary
        )}
      >
        <Container>
          <Row>
            <Col>
              <h2 className={css(Classes.title)}>{t('contact.titre')}</h2>
              {Content}
            </Col>
          </Row>
        </Container>
      </Section>
    )
  }
}

Contact.defaultProps = {
  isDisplayFromHome: true,
}

export default withNamespaces('home')(Contact)
