import React, { Component } from 'react'
import { StyleSheet, css } from 'aphrodite'
import { withNamespaces } from 'react-i18next'
import { Section, Signature, Button } from 'inno-components'
import { Link as ScrollLink } from 'react-scroll'
import { Container, Row, Col } from 'react-grid-system'
import Map from '../home/Map'
import Colors from '../../styles/colors'
import Config from '../../Config'
import Classes from '../../styles/classes'

import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'

const s = {
  href: {
    color: Colors.white,
    textDecoration: 'none',
  },
}

const styles = StyleSheet.create({
  title: {
    fontSize: '1em',
    fontWeight: 600,
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '3em',
    color: Colors.white,
  },
  input: {
    padding: '1em',
    borderRadius: 0,
    color: Config.colors.primary,
    backgroundColor: Config.colors.primary,
    border: `solid 2px ${Colors.white}`,
  },
  titleAddress: {
    color: Colors.white,
  },
  padd: {
    padding: '2em',
  },
  columnAddress: {},
  item: {
    color: Colors.white,
  },
  marginLeft: {
    marginLeft: '1.8em',
  },
  icon: {
    color: Colors.white,
  },
  button: {
    border: `solid 2px ${Config.colors.primary}`,
  },
  section: {
    backgroundColor: '#000',
    color: '#FFF',
    textAlign: 'center',
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
  },
  sectionMap: {
    borderTop: `solid 10px ${Config.colors.primary}`,
  },
  signature: {
    color: '#FFFFFF',
    textDecoration: 'none',
  },
  iconSignature: {
    color: '#FC0D1B',
  },
  scrollLink: {
    cursor: 'pointer',
  },
})

class Footer extends Component {
  state = {
    posts: [1, 2, 3, 4],
  }
  componentDidMount() {}
  render() {
    const { t, isDisplayedFromHome } = this.props

    return (
      <footer>
        <Section className={css(styles.sectionMap)}>
          <Map />
        </Section>
        <Section className={css(Classes.section, styles.section)}>
          <Container>
            <Row>
              <Col>
                <h3 className={css(styles.title, styles.titleAddress)}>
                  {t('footer.liens.titre')}
                </h3>
                <ul className={css(styles.ul)}>
                  <li>
                    <ScrollLink
                      className={css(styles.scrollLink)}
                      to="notre-entreprise"
                      smooth={true}
                      duration={500}
                    >
                      {t('footer.liens.items.0')}
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      className={css(styles.scrollLink)}
                      to="vos-campagnes"
                      smooth={true}
                      duration={500}
                    >
                      {t('footer.liens.items.1')}
                    </ScrollLink>
                    <ul className={css(styles.ul)}>
                      <li>
                        <Link page={pagesInfos.ComponentCampagneDeNotoriete}>
                          {t('footer.liens.items.2')}
                        </Link>
                      </li>
                      <li>
                        <Link
                          page={pagesInfos.ComponentCampagneDeCommunication}
                        >
                          {t('footer.liens.items.3')}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ScrollLink
                      className={css(styles.scrollLink)}
                      to="contact"
                      smooth={true}
                      duration={500}
                    >
                      {t('footer.liens.items.4')}
                    </ScrollLink>
                  </li>
                  <li>
                    <ScrollLink
                      className={css(styles.scrollLink)}
                      to="references"
                      smooth={true}
                      duration={500}
                    >
                      {t('footer.liens.items.5')}
                    </ScrollLink>
                  </li>
                </ul>
              </Col>
              <Col>
                <h3 className={css(styles.title, styles.titleAddress)}>
                  {t('footer.contact.titre')}
                </h3>
                <ul className={css(styles.ul)}>
                  <li>{t('footer.contact.items.0')}</li>
                  <li>{t('footer.contact.items.1')}</li>
                  <li>{t('footer.contact.items.2')}</li>
                  <li>
                    <a
                      style={s.href}
                      className="phone-call"
                      href="tel://+3227912113"
                    >
                      +32 (0)2 791 21 13
                    </a>
                  </li>
                  <li>
                    <a
                      style={s.href}
                      className="phone-call"
                      href="tel://+32477872806"
                    >
                      +32 (0)477 87 28 06
                    </a>
                  </li>
                  <li>
                    <a style={s.href} href="mailto:info@screeningmedia.com">
                      {t('footer.contact.items.4')}
                    </a>
                  </li>
                </ul>
              </Col>
              <Col>
                <h3 className={css(styles.title, styles.titleAddress)}>
                  {t('footer.horaires.titre')}
                </h3>
                <ul className={css(styles.ul)}>
                  <li>{t('footer.horaires.items.0')}</li>
                  <li>{t('footer.horaires.items.1')}</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <div style={{ padding: '2em', textAlign: 'center' }}>
                  <Signature
                    className={css(styles.signature)}
                    iconClassName={css(styles.iconSignature)}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      </footer>
    )
  }
}

export default withNamespaces('home')(Footer)
